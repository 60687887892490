import React, { useState } from "react";

const FlipCard = ({ children }) => {
  return (
    <div className='mx-auto bg-transparent h-[100%] cursor-pointer group perspective'>
      <div className={`preserve-3d duration-1000 h-[100%] m-1 group-hover:my-rotate-y-180`}>
        <div className='absolute h-[100%] w-[100%] shadow backface-hidden transform'>
          {children && children[0]}
        </div>
        <div className='h-[100%] w-[100%] shadow my-rotate-y-180 backface-hidden overflow-hidden'>
          {children && children.length >= 2 && children[1]}
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
