import React from "react";
import FlipCard from "./FlipCard";

const ProjectCard = ({
  title,
  img,
  description,
}) => {
  return (
    <div className='h-36 m-2 aspect-[16/9]'>
      <FlipCard>

        { /* Front */ }
        <div className="bg-slate-700 h-full w-full rounded-lg bg-cover"style={{backgroundImage: `url(${img})`}}>
          <div className="bg-slate-200 h-1/6 w-full bg-opacity-50 text-right pr-3 font-bold rounded-t-lg">
            {title}
          </div>
        </div>

        { /* Back */ }
        <div className="bg-slate-200 h-full w-full rounded-lg bg-cover bg-" style={{backgroundImage: `url(${img})`, transform: "scaleX(-1)"}} onClick={()=>alert("Klick!")}>
          <div className="bg-slate-200 h-1/6 w-full text-right pr-3 font-bold rounded-t-lg" style={{transform: "scaleX(-1)"}}>
            {title}
          </div>
          <div className="bg-slate-200 w-full h-full bg-opacity-50 text-left px-3 py-1" style={{transform: "scaleX(-1)"}}>
            {description}
          </div>
        </div>
      </FlipCard>
    </div>
  );
};

export default ProjectCard;
