import { Disclosure} from '@headlessui/react'
import { isMobile } from 'react-device-detect';

const PUBLIC_URL = process.env.PUBLIC_URL?process.env.PUBLIC_URL:'';

const navigation = [
  { name: 'Home' },
  { name: 'About' },
  { name: 'Projects' },
  { name: 'Contact' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function Menu({item, setItem}) {
  return (
    <Disclosure as="nav" className="bg-slate-900 bg-opacity-50 rounded-t-lg">
      <div className="mx-auto px-4">
        <div className={classNames(isMobile?"h-24":"h-16", "relative flex items-center justify-between")}>
          <div className="flex flex-1 items-center justify-center">
              <div className="flex space-x-4">
                {navigation.map((i) => (
                  <a
                    key={i.name}
                    onClick={() => setItem(i.name)}
                    className={classNames(
                      item === i.name ? 'bg-gray-700 text-white bg-opacity-75' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'rounded-md cursor-pointer font-medium', isMobile ? "px-4 py-6 text-xl" : "px-3 py-3 text-sm"
                    )}
                  >
                    {i.name}
                  </a>
                ))}
              </div>
          </div>
        </div>
      </div>
    </Disclosure>
  )
}

export default Menu;