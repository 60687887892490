import Header from './components/Header';
import './App.css';
import Menu from './components/Menu';
import ContactForm from './components/ContactForm';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import ProjectCard from './components/ProjectCard';

const PUBLIC_URL = process.env.PUBLIC_URL?process.env.PUBLIC_URL:'';

function App() {
  const [currentPage, setCurrentPage] = useState('home');

  return (
      <div className={`mx-auto grid grid-cols-8 gap-4 ${isMobile ? "py-1 px-1 w-[100%]" : "py-4 px-4 max-w-[1200px]"}`}>
        {/* Menu */}
        <div className='col-span-8'>
          <Header>
            <div className="col-span-1"></div>
            <div className="sm:col-span-5 md:col-span-4 lg:col-span-3 ">
              <Menu item={currentPage} setItem={setCurrentPage} />
            </div>
          </Header>
        </div>

        { /* Home */  
          currentPage === 'Home' &&
          <div className="bg-slate-700 col-span-8">HOME</div>
        }
        { /* About */ 
          currentPage === 'About' &&
          <div className="bg-slate-700 col-span-8">ABOUT</div>
        }
        { /* Projects */  
          currentPage === 'Projects' && 
          <div className="col-span-8 border-2 rounded-lg flex flex-wrap justify-center">
            <ProjectCard img="bg.png" title="My First Project" description="HELLO WORLD" />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
          </div>
          
        }
        { /* Contact */ 
          currentPage === 'Contact' && 
          <>
            <div className={`rounded-lg ${isMobile ? "col-span-8" : "sm:col-span-8 md:col-span-7 lg:col-span-6"} border-2`}>
              <ContactForm />
            </div>
            {!isMobile && <div className="sm:hidden md:block md:col-span-1 lg:col-span-2 rounded-lg bg-center bg-opacity-20" style={{ backgroundImage: 'url(bg.png)' }}></div>}
          </>
        }

        { /* Footer */ }

        <div className="bg-slate-100">.</div>
        <div className="bg-slate-200">.</div>
        <div className="bg-slate-300">.</div>
        <div className="bg-slate-400">.</div>
        <div className="bg-slate-500">.</div>
        <div className="bg-slate-600">.</div>
        <div className="bg-slate-800">.</div>
        <div className="bg-slate-900">.</div>

      </div>
  );
}

export default App;
